import { useTranslation } from 'react-i18next'
import { Button } from '~/core/ui/Button'
import pathConfiguration from 'src/configuration/path'
import Link from 'next/link'
import { URL_REGISTER_APP } from '~/core/constants/url'
import { ICONS } from '~/core/constants/resources'
import Image from 'next/image'
import { FCC } from '~/core/@types/global'
import SectionContainer from './components/section/SectionContainer'

const JourneySection: FCC<{
  className?: string
  hideFeatures?: boolean
  hideTryFreeAction?: boolean
  isGhostBookDemoBtn?: boolean
}> = ({
  className,
  hideFeatures = false,
  hideTryFreeAction = false,
  isGhostBookDemoBtn = true
}) => {
  const { t, i18n } = useTranslation()
  return (
    <SectionContainer
      title="lp:journey_section:title"
      description="lp:journey_section:description"
      tagLabel="lp:journey_section:join_with_us"
      tagColor="text-purple-500"
      className={className}>
      <div className="flex align-middle w-full justify-center gap-4 mt-[40px]">
        {!hideTryFreeAction && (
          <Button
            label={`${t('lp:try_for_free')}`}
            className="h-[44px]"
            fontWeight="font-medium"
            onClick={() => window.open(URL_REGISTER_APP, '_self')}
          />
        )}
        <Link href={pathConfiguration.contactRequest} locale={i18n?.language}>
          <Button
            label={`${t('lp:book_a_demo')}`}
            className={`h-[44px] ${isGhostBookDemoBtn && 'bg-white !text-gray-900 hover:!bg-gray-100 border border-gray-300'}`}
            fontWeight="font-medium"
          />
        </Link>
      </div>

      {!hideFeatures && (
        <div className="mt-[40px] flex flex-col gap-6 tablet:flex-row tablet:justify-between px-4 py-6 tablet:p-10 bg-[#F6F9FE] border border-transparent rounded-xl">
          <div className="flex flex-row gap-4">
            <Image
              src={ICONS.message}
              loading="lazy"
              width={0}
              height={0}
              className="w-12 h-auto"
              alt="message"
            />
            <div className="flex flex-col">
              <div className="font-medium leading-7 text-[19px]">
                {t('lp:journey_section:day_free_trial')}
              </div>
              <p className=" leading-[26px] text-[17px]">
                {t('lp:journey_section:allowing_experience')}
              </p>
            </div>
          </div>

          <div className="flex flex-row gap-4">
            <Image
              src={ICONS.support}
              loading="lazy"
              width={0}
              height={0}
              className="w-12 h-auto"
              alt="support"
            />
            <div className="flex flex-col justify-between">
              <div className="font-medium leading-7 text-[19px]">
                {t('lp:journey_section:full_support')}
              </div>
              <p className="leading-[26px] text-[17px]">
                {t('lp:journey_section:assistance_whenever')}
              </p>
            </div>
          </div>

          <div className="flex flex-row gap-4">
            <Image
              src={ICONS.credit}
              loading="lazy"
              width={0}
              height={0}
              className="w-12 h-auto"
              alt="credit"
            />
            <div className="flex flex-col justify-between">
              <div className="font-medium leading-7 text-[19px]">
                {t('lp:journey_section:no_credit_card')}
              </div>
              <p className=" leading-[26px] text-[17px]">
                {t('lp:journey_section:no_credit_card_des')}
              </p>
            </div>
          </div>
        </div>
      )}
    </SectionContainer>
  )
}

export default JourneySection
