import { DotFilledIcon } from '@radix-ui/react-icons'

const DotBadge = ({
  label,
  dotClassName = 'text-blue-500'
}: {
  label: string
  dotClassName?: string
}) => {
  return (
    <div className="border rounded-full border-gray-200 flex items-center desktop:py-[2px] pr-4 pl-2 font-medium text-base desktop:text-[17px] desktop:leading-[26px]">
      <DotFilledIcon className={`h-7 w-7 ${dotClassName}`} />
      {label}
    </div>
  )
}
export default DotBadge
